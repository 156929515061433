/* @import 'react-big-calendar/';
 */

.events-header {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    width: 100%;
    height: 100vh;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:100% 0%;
    margin-bottom: -400px;
}



.events-header h1 {
    font-size: 18vw !important;
    color: #308f59;
    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    font-family: 'Staatliches', cursive;
    padding-left: 20%;
    padding-bottom: 50%;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
}


.calendar {
    margin-left: 15%;
    margin-right: 15%;

}

.rbc-toolbar-label {
    font-weight:700;
    font-size: 4vw;
    color:#308f59;
    text-transform: uppercase !important;
}


.events-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}


.title{
    display: flex;
    flex-direction: column;
    height: 40vh !important;
    display: flex;
    padding-top: 5%;
    padding-bottom: 5%;
    font-size: 1.3vw !important;
    justify-content: center;
    /* background-color: rgb(211, 213, 215); */
    align-items: center;

    background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.glass {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(240, 248, 255, 0.657);
    width: 100%;
    box-shadow: 0 8px 32px 0 rgba(194, 195, 210, 0.37);
    backdrop-filter: blur( 3px );
    
}
.title-section {
    display: flex;
    gap: 1rem;
}




.volunteer-section {
    background-color: rgba(0, 0, 0, 0.885);
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 15%;
    margin-left: 10%;
    margin-right: 10%;
    padding-top: 10%;
    padding-bottom: 10%;
    border-radius: 20px;
    text-align: left;
    margin-bottom: 20%;
}

.become-a-volunteer {
    color: white;
}

.register-button {
    background-color: #308f59;
    color: white;
    font-size: 3vw;
    white-space:nowrap;
    margin-top: 5%;
    margin-bottom: 10%;
    padding-top: 2.5vh;
    padding-bottom: 2.5vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-weight: 700;
    outline: none;
    border: 3px solid #308f59;
    border-radius: 10px;
}



@media (min-width: 320px){
    .events-header h1 {
        font-size: 7vw !important;
        text-align: left;
        text-shadow: -5px 6px 4px rgb(25, 24, 24);
    }
}

@media (min-width: 470px){
	
.events-header {

    margin-bottom: -300px;
}

}

@media (min-width: 600px){
	
    .events-header {
    
        margin-bottom: -200px;
    }
}
@media(min-width:700px) {
    .volunteer-section {
        background-color: rgb(255, 255, 255);
        padding-left: 3%;
        padding-right: 3%;
        margin-top: 5%;
        margin-left: 30%;
        margin-right: 30%;
        font-size: 1.5vw;
        padding-top: 3%;
        padding-bottom: 1%;
        border-radius: 20px;
        text-align: center;
        margin-bottom: 5%;
        filter: drop-shadow(0px 9px 30px rgba(152, 147, 147, 0.972));
    }
    
    .become-a-volunteer {
        color: rgb(0, 0, 0);
    }
    
    .register-button {
        background-color: #308f59;
        color: white;
        font-size: 0.8vw;
        white-space:nowrap;
        margin-top: 3%;
        margin-bottom: 8%;
        padding-top: 2vh;
        padding-bottom: 2vh;
        padding-left: 1.5vw;
        padding-right: 1.5vw;
        font-weight: 700;
        outline: none;
        border: 3px solid #308f59;
        border-radius: 10px;
        transition: 0.3s;
    }

    .register-button:hover {
        background-color: #282828;
        color: white;
        border: 3px solid #282828;
        transition: 0.3s;

    }
}

@media (min-width: 769px){
    /* .events-header h1 {
        font-size: 7vw !important;
        text-align: left;
        padding-left: 20%;
        padding-bottom: 80%;
        text-shadow: -5px 6px 4px rgb(25, 24, 24);
    } */

    .events-header {
        display: flex;
        justify-content: left;
        align-items: center;
        color: white;
        width: 100%;
        height: 100vh;
        background-size:cover;
        background-repeat: no-repeat;
        background-position:100% 0%;
        padding-top: 400px;
        margin-bottom: 50px;
    }
}

@media (min-width: 1025px){
    .events-header h1 {
      font-size: 4.5vw !important;
      text-align: left;
      padding-left: 25%;
      padding-bottom: 90%;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
  }


  .events-header {
    display: flex;
    justify-content: left;
    align-items: center;
    color: white;
    width: 100%;
    height: 85vh;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0% 0%;
}
}
