.gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}

.gallery {
    margin-top: 3%;
    margin-left: 15%;
    margin-right: 15%;

    white-space: nowrap;
    display: flex;
    flex-direction: column;
}

.gallery h3 {
    font-size: 3vw;
    text-align: center;
    margin-top: 4%;
    
}

.gallery-container > h2 {
    font-size: 4vw;
    margin-top: 3%;

    font-weight: 700;
    color:  #308f59;
 
}

.gallery-header {
    display: flex;

    align-items: center;
    color: white;
    width: 100%;
    height: 85vh;

  
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0% 0%;
}

.gallery-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}


.gallery-header h1 {
    font-size: 15vw !important;
    padding-left: 60%;
    font-family: 'Staatliches', cursive;

    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
  }

  
@media (min-width: 769px){

    .gallery-header h1 {
      font-size: 7vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
      
  }

  .gallery h3 {
    font-size: 2.5vw;
    
}
   
}

  
@media (min-width: 1025px){
    .gallery-header h1 {
      font-size: 6vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
  }
  .gallery h3 {
    font-size: 1.8vw;
    
}
}