.story {
    display: flex;
    background-color: white;
    text-align: center;
    color: black;
    align-items: center;
    margin-bottom:8%;
 
    padding-left: 0%;
    padding-right: 5%;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    filter: drop-shadow(5px 9px 5px rgba(6, 0, 0, 0.269));
    width: 100%;
}
.stories h1 {
    font-size:30px;
    margin-bottom: 35px;
    font-weight:700;
    color:black;
}
.story img {
    width: 40vw;
    height: auto;
    align-items: center;
    filter: drop-shadow(5px 9px 5px rgba(42, 41, 41, 0.136));

    margin-bottom: 10%;
    
}
.story-body {
    
    max-width: 90vw;
    text-align: center;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
}

.story-body h4 {
    font-size: 1.5vw;
    text-align: left;
    white-space: nowrap;
    font-weight: 700;
    
}
.story-body p {
    white-space: initial;
    font-size: 1vw;
    text-align: left;
    color: rgb(92, 89, 89);
   font-weight: 500;
}


.hide {
    display: none;
}


@media(max-width: 700px) {
    .story {
        flex-direction: column !important;
        padding-top: 5%;
        padding-bottom: 5%;
        
    }

    .story-body h4 {
        font-size: 3vw;
        text-align: center;
    }
    .story-body p {
        font-size: 2vw;
        text-align: center;
    }
}

@media(min-width: 700px) {
    .story img {
        width: 14vw;
        height: auto;
        border-radius: 0%;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
        margin-bottom: 0%;
    }
    .story-body h4 {
        text-align: left;
    }
    .story-body p {
        text-align: left;
    }
}