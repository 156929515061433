.missing-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: white;
    width: 100%;
    height: 85vh;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:10% 100%;
    color: #ffffff;
    font-family: 'Staatliches', cursive;
    text-align: center;
    background-color: #a26add;
}

.missing-header h1 {
    font-size: 120px;
    
}
.missing-header h3 {
    font-family: 'Segoe UI';


}

.go-home {
    border-radius: 50px;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px solid #282828;
    transition: 0.3s;
}
.go-home {
    border-radius: 50px;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border: 3px solid #282828;
    transition: 0.3s;
}

.go-home:hover {
    transition: 0.3s;
    background: #282828;
    color: white;
}