.reach-our-goals {
	background-image: url('../img/classroom.webp');
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.reach-our-goals-hide {
	display: none;
}

.reach-our-goals-content {
	padding-top: 4vh;
	padding-bottom: 4vh;
	padding-left: 10%;
	padding-right: 10%;
}


.reach-our-goals-overlay{
	background-color: rgba(0, 0, 0, 0.588);
	width: 100%;
	color: white;
	padding-top: 5% !important;

}

.reach-our-goals button {
	background-color: #FFF500;
	color: rgb(61, 59, 59);
	font-weight: 900;
	padding-left: 5vw;
	padding-right: 5vw;
	padding-top: 1.4vh;
	padding-bottom: 1.4vh;
	border: 2px solid #FFF500;
	margin-bottom: 5%;
	border-radius: 40px;
	margin-top: 5%;
	font-size: 3vw;
	transition:0.3s;
}

.reach-our-goals button:hover {
	background-color: white;
	color: #282828;
	border: 2px solid white;
	transition:0.3s;

}

.reach-our-goals-content h1 {
	color: #a26add !important;
    font-size: 7vw;
    font-weight: 700;
}

.reach-our-goals-content h4 {
    margin-top: 5%;
    font-size: 5vw;
}

.reach-our-goals-content h5 {
    margin-top: 5%;
    font-size: 3.5vw;
    margin-bottom: 5%;
}


@media (min-width: 769px){


	.reach-our-goals-content h1 {

		font-size: 4.5vw;

	}
	
	.reach-our-goals-content h4 {

		font-size: 3vw;
	}
	
	.reach-our-goals-content h5 {

		font-size: 2vw;

	}
	.reach-our-goals button {

		font-size: 1.5vw;
	}

   
}

  
@media (min-width: 1025px){

	.reach-our-goals-content {
		padding-top: 0vh;
		padding-bottom: 0vh;
	}
	.reach-our-goals-content h1 {

		font-size: 3vw;

	}
	
	.reach-our-goals-content h4 {
		margin-top: 3%;
		font-size: 2.5vw;
	}
	
	.reach-our-goals-content h5 {

		font-size: 1.3vw;
		margin-top: 3%;
	}
	.reach-our-goals button {

		font-size: 1vw;
		padding-left: 4vw;
		padding-right: 4vw;
		padding-top: 2vh;
		padding-bottom: 2vh;
		margin-top: 0.5%;
	}
}