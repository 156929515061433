.container {
    background-color: red !important;
}

/* .progress-bar {
    margin-bottom: 7%;
    margin-top: 7%;

    margin-right: 15%;
} */
.cause {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: left !important;
	align-items: center;
	padding-top: 10%;
	padding-bottom: 10%;
	min-width: 22vw;
	margin-right: 1%;
	margin-top: 5%;
	margin-bottom: 5%;
	margin-left: 1%;
	background-color: #ffffffde;
	filter: drop-shadow(0px 9px 5px rgba(6, 0, 0, 0.269));
}
.cause img {
	width: 20rem;
	height: auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 10px;
}

.cause-content {
	padding-left: 3%;
	padding-right: 3%;
	text-align:center;
}

.cause-content h2 {
	font-size: 7vw;
	padding:15px;
}
.cause-content h4 {
	font-size: 5.5vw;
    color: rgba(78, 82, 78, 0.605);
}

.cause-content button {
	color: #ffffff;
	background-color: #308f59;
	border: 3px solid #308f59;
	font-size: 4vw;
	font-weight: 700;
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
	padding-left: 3vw;
	padding-right: 3vw;
	transition: 0.3s;
	border-radius: 10px;
}

.cause-content button:hover {
	background-color: #282828;
	border: 3px solid #282828;
	color:#ffffff;
	transition: 0.3s;
}

.cause-container {

    padding-left: 13%;
    padding-right: 13%;
}


@media (min-width: 450px){
    .cause-content button {
        font-size: 2vw;
    }

    .cause-content h2 {
        font-size: 5vw;
    }
    .cause-content h4 {
        font-size: 4vw;
        color: rgba(78, 82, 78, 0.605);
    }
    .cause img {
		width: 25vw;
		height: auto;
		padding-bottom: 5%;
		padding-top: 20%;
		border-radius: 10px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	
	
		
	}
}
@media (min-width: 600px){
    .cause-content button {
        font-size: 2vw;
    }

    .cause-content h2 {
        font-size: 4vw;
    }
    .cause-content h4 {
        font-size: 3vw;
        color: rgba(78, 82, 78, 0.605);
    }
    .cause img {
		width: 25vw;
		height: auto;
		padding-bottom: 5%;
		padding-top: 0%;
		border-radius: 10px;

	}
	.cause-container {

		margin-left: 15%;
		margin-right: 15%;
	}
}

@media (min-width: 1025px){
	.cause-content button {
		font-size: 0.8vw;
		padding-left: 1vw;
		padding-right: 1vw;
		padding-top: 1.3vh;
		padding-bottom: 1.3vh;
	}
	.cause-container {
		display:flex;
		margin-left: 10%;
		margin-right: 10%;
	}
	.cause {

		padding-top: 2%;
		padding-bottom: 5%;
	}


    .cause-content h2 {
        font-size: 1.5vw;
    }
    .cause-content h4 {
        font-size: 1.1vw;
        color: rgba(78, 82, 78, 0.605);
    }
    .cause img {
		width: 12vw;
		height: auto;
		padding-bottom: 5%;
		padding-top: 5%;
		border-radius: 10px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}
