.donate-header {
    display: flex;
    align-items: center;
    color: white;
    width: 100%;
    height: 85vh;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0% 30%;
}


.donate-header h1 {
    font-size: 15vw !important;
    padding-left: 30%;
  
    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    font-family: 'Staatliches', cursive;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
    
}
.donate-form-section p {
  text-align: left;
}
.donate-form-section h1 {
  font-size: 8vw;
  font-weight: 700;
  text-align: left;
}
.donate-form-section h3 {
  font-size: 6vw;
  font-weight: 700;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
}

.donate-form-section button {
  font-size: 5vw;
  font-weight: 900;
  padding-left: 8vw;
  padding-right: 8vw;
  margin-top: 10%;
  margin-bottom: 10%;
  margin-left: 2%;
  margin-right: 2%;
  background-color: white;
  border-radius: 10px;
  border: solid 2px black;
}

.donate-container {
  display: flex;
  height: 90vh;
  justify-content: center;
  flex-direction: column;
  margin-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  gap: 1%;
  width: 100%;
  
}

.donate-form-inputs {

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.donate-form-input-group {
  display: flex;
  flex-direction: column;
}
.donate-form-input-group label {
  text-align: left;
  font-weight: 700;
  margin-top: 2vh;
}

.donate-form-input-group input {

  height: 5vh;
  max-height: 2.5rem;

  border: 2px solid rgba(119, 116, 116, 0.599);
  border-radius: 10px;

  

}


.donate-submit {
  background-color: #308f59 !important;
  color: white;
  padding-top: 1.3vh;
  padding-bottom: 1.3vh;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-bottom: 0% !important;

  font-size: 2.5vw !important;
  font-weight: 700;
  border: none !important;
 
}
.form-body select {
  border: 2px solid black;
  height: 5vh;
  font-size: 3vw;
  font-weight: 500;
  padding-left: 1vw;
  border-radius: 10px;
  }
  
  .amount-options > input {
      margin-left: 0.5vw;
      border: 2px solid black;
      height: 4vh;
      font-size: 1.3vw;
      font-weight: 500;
      padding-left: 1vw;
      border-radius: 10px;
  }
  
.donate-form-section {
  text-align: center;

}

.donate-form-section > p {
  text-align: center;
  padding-bottom: 15%;
}


@media (min-width: 2px){
  .donate-header h1 {
    font-size: 10vw !important;
    text-align: left;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
    
}

}
.filler {
  background-image: url('../img/student.webp');
  height: 60vh;
  width:60vw;
  margin-top: 5%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;


}
.donate-form-section-formatting > p {
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  margin-top: 5%;
  font-size: 4vw;
  font-weight: 500;
}

.donate-container {

  padding-left: 0% !important;
  padding-right: 0% !important;
  background-color: #006b2b36;
  display: flex;
  align-items: center;
  
  
}
  @media (min-width: 769px){

    .filler {
      background-image: url('../img/student.webp');
      height: 90vh;
      width:60vw;
      margin-top: 5%;
 
    
    
    }
    .donate-header h1 {
      font-size: 7vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
    }

    .donate-container {

     flex-direction: row;
      
      
    }

    .form-body select {
      border: 2px solid black;
      height: 5vh;
      font-size: 1.3vw;
      font-weight: 500;
      padding-left: 1vw;
      border-radius: 10px;
      }

    .donate-form-section {
      margin-left: 3%;
      margin-right: 4%;
      margin-top: 10%;
      background-color: white;
      border-radius: 15px;
      padding: 4%;
      display: flex;  
      



    }

    
    .donate-form-input-group input {
      width: 15vw;
      height: 5vh;
      max-height: 2.5rem;
      resize: none;
      border: 2px solid rgba(119, 116, 116, 0.599);
      border-radius: 10px;
      margin-bottom: 0vh;
      margin-top: 0vh;
      
    
    }

    .donate-form-section button {
      font-size: 3vw;
      font-weight: 900;
      padding-left: 3vw;
      padding-right: 3vw;
      margin-top: 10%;
      margin-bottom: 10%;
      margin-left: 2%;
      margin-right: 2%;
      background-color: white;
      
    }
    

    .donate-form-inputs {
      flex-direction: row;
      gap: 5vw;
    }
    .donate-form-input-group input {
      width: 15vw;
    }

    .donate-form-section h1 {
      font-size: 2.5vw;

    }
    .donate-form-section h3 {
      font-size: 1.5vw;
      text-align: center;
    }
    .donate-form-section h3 {
   
      margin-top: 5%;
      margin-bottom: 5%;
   
    }
    

    .donate-form-formatting {
      margin-left: 25%;
      margin-right: 25%;
    }
    .donate-form-section-formatting > p {
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 3%;
      text-align: center;
      font-size: 1.5vw;
      font-weight: 500;
      color: #000000ad;
      padding-left: 10% !important;
    }

    .form-body select {
      border: 2px solid black;
      height: 5vh;
      font-size: 1.3vw;
      font-weight: 500;
      padding-left: 1vw;
      border-radius: 10px;
      }
      
      .amount-options > input {
          margin-left: 0.5vw;
          border: 2px solid black;
          height: 4vh;
          font-size: 1.3vw;
          font-weight: 500;
          padding-left: 1vw;
          border-radius: 10px;
      }
      
    .donate-submit {
      background-color: #308f59 !important;
      color: white;
      padding-top: 1.5vh;
      padding-bottom: 1.5vh;
      padding-left: 2.5vw;
      padding-right: 2.5vw;
  
   
      font-size: 1vw !important;
    
     
    }

}

@media (min-width: 1025px){
    .donate-header h1 {
      font-size: 6vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
      
  }

  .donate-form-section button {
    font-size: 1.8vw;
    font-weight: 900;
    padding-left: 2vw;
    padding-right: 2vw;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 2%;
    margin-right: 2%;
    background-color: white;
    
  }
  
  }