.history-header {
    display: flex;

    align-items: center;


    color: white;
    width: 100%;
    height: 85vh;

  
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0% 0%;
}



.history-buttons button {
    background-color: #008000;
    color: rgb(0, 0, 0);
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 2vh;
    padding-bottom: 2vh;
 
    border: 2px solid #008000;
    border-radius: 40px;
}


.history-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 6%;
    /* background-image: url('../img/relaxing.jpg'); */
    color: white;
  
}

.disastor-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 6%;
  /* background-image: url('../img/relaxing.jpg'); */
  color: white;

}

.history-buttons {
    position: sticky;
    display: flex;
    gap: 0.7rem;
    align-items: center;
  }
  

  .history-buttons > button {
    outline: none;
    background: none;
    border: 3px solid #a26add;
    border-radius: 10px;
    padding-left: 4vw;
    padding-right: 4vw;
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    font-size: 3.5vw;
    color: #a26add;
    font-weight: 700;
    cursor: pointer;
  }

  .button-active {
    background-color: #a26add !important;
    border: 3px solid #a26add !important;
    color: rgb(255, 255, 255) !important;
    transition: 0.5s;
    white-space: nowrap;
  }


  .funding-container h2 {
      color: black;
      text-align: center;
      padding: 5%;
      font-weight: 500;
      font-size: 4vw;
      color: #308f59;
      font-weight: 700;

  }
  
  .background-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left !important;
      color: black;
      margin-left: 6%;
      margin-right: 6%;
      margin-top: 6%;
      margin-bottom: 6%;
  }

  .background-container h2 {
    font-size: 10vw;
    color: #308f59;
    font-weight: 700;
  }


  .history-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left !important;
    color: black;
    margin-left: 6%;
    margin-right: 6%;
    margin-top: 6%;
    margin-bottom: 6%;
}

.history-container h2 {
  font-size: 10vw;
  color: #308f59;
  font-weight: 700;
}


.disastor-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left !important;
  color: black;
  margin-left: 6%;
  margin-right: 6%;
  margin-top: 6%;
  margin-bottom: 6%;
}

.disastor-img-container {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.disastor-container img {
  width: 80vw;
  height: auto;

}

.disastor-container h2 {
font-size: 10vw;
color: #308f59;
font-weight: 700;
}


    .bf-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: left !important;
      color: black;
      margin-left: 6%;
      margin-right: 6%;
      margin-top: 6%;
      margin-bottom: 6%;
  }

  .funding-container h2 {
    font-size: 10vw;
  }


  .presidents-container h2 {
      text-align: center;
  }
  .presidents-container {
    text-align: left;
    margin-top: 5%;
  }



.history-header h1 {
  font-size: 15vw !important;
  padding-left: 30%;
  font-family: 'Staatliches', cursive;
  font-weight: 900;
  text-align: left;
  white-space: nowrap;
  text-shadow: -5px 6px 4px rgb(25, 24, 24);
  
}
@media (min-width: 320px){

}

@media (min-width: 481px){
    .has-dropcap::first-letter {
        font-size: 10vw;
        float: left;
        color: #a26add;
        margin: -0.35em 0.3em -0.4em 0;
        padding-left: 4%;
      }
      
     
}

@media (min-width: 769px){
    .history-buttons > button {
        font-size: 1.5vw;
  
      }
    
    .history-header h1 {
      font-size: 7vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
      
  }
    .background-container h2 {
        font-size: 5vw;

      }
      .background-container p {
        font-size: 1.5vw;
        font-weight: 500;
        color: rgb(42, 39, 39);
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 5%;
      }
      
      .disastor-container h2 {
        color: #308f59;
        font-size: 5vw;

      }
      .disastor-container p {
        font-size: 1.5vw;
        font-weight: 500;
        color: rgb(42, 39, 39);
        margin-left: 10%;
        margin-right: 10%;
        margin-top: 5%;
      }
      .funding-container h2 {
        font-size: 5vw;
       
      }
  
   

   
}

@media (min-width: 1025px){
  .history-header h1 {
    font-size: 6vw !important;
    text-align: left;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
    
}
}



@media only screen and (min-width: 1201px) {
}
