.nav-container {
    position:fixed;
    backdrop-filter: blur( 10px );
    width: 100%;
    background-color: rgba(205, 145, 211, 0.683);
    z-index: 10;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.381);
    
}

.nav {
    display: flex;
    /* border-top: 2px solid rgba(0, 0, 0, 0.157); */
    align-items: center;
    z-index: 4;
    justify-content: center;
    width: 100%;
    transition: 0.3s;
    white-space: nowrap;
    height: 6vh !important;
    padding-top: 0.7rem;
    padding-bottom: 3rem;
    min-height:2.5rem;

}



.main-nav {
    display: flex;
    justify-content: space-around;
    padding-top: 0.5%;
    transition: 1s;
    animation: fadeOut 5s;

}

.nav li {
    display: inline-block;
    list-style: none;
    
}

.nav ul {
    display: flex;
    gap: 4rem;
    justify-content: center !important;


   
}


.nav a {
    text-decoration: none;
    color: #ffffff;

    letter-spacing: 3px;
    font-size: 1.8vw;
    font-weight: 900;
    font-family: 'Staatliches', cursive;
}


.nav-logo {
    display: none;
   
 

}

.nav-logo img {
    width: 5vw;
    height: auto;
}

.nav-logo h2 {
    font-weight: 700;
    font-size: 5vw;
    color: #308f59;
    transition:ease-in-out 0.3s;
    font-family: 'Staatliches', cursive;
}
.nav-logo h2:hover {
   color: rgb(255, 255, 255);
   cursor: pointer;
   transition:ease-in-out 0.3s;
}

.list-item-selected {
    /* border-bottom: white solid 2px; */
    transition: 0.3s ease-in-out;
}
.nav a:hover {
    color: #181717;
    transition: 0.1s
}

.hide-main-nav {
    display:none !important;
    transition: 0.3s;
}

.nav-container-scrolled {
    position:fixed;
    backdrop-filter: blur( 10px );
    width: 100%;
    background: #308f59;
    z-index: 99;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.222);
}

.nav-scrolled li {
    display: inline-block;
    list-style: none;
    
}
.nav-scrolled ul {
    display: flex;
    gap: 4rem;
    justify-content: center !important;
    padding-top: 1.5rem;  

}
.nav-scrolled a {
    text-decoration: none;
    color: rgb(255, 255, 255);
    font-size: 1.5vw;
    font-weight: 900;
    font-family: 'Staatliches', cursive;
    
}
.nav-scrolled a:hover {
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-size: 1.5vw;
    font-weight: 900;
    font-family: 'Staatliches', cursive;
    
}


        .hamburger-menu {
            width: 4vw !important;
            height: auto !important;
            color: white;
            cursor: pointer;
        }
    
     
    
        @media (min-width: 768px) {

            .nav-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5rem;
                color: rgb(255, 255, 255);
                height: 10vh;
                min-height:2.5rem;
             
            
            }
            .today {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid rgb(255, 255, 255);
                width: 4vw;
                height: 4vw;  
                flex-direction: column;  
                  
                transition:ease-in-out 0.3s;
            }
           
            .today:hover .date {
              
                color: #ffffff;
            }

            .event-calendar {
                color: #ffffff;
                font-size: 1.4vw;
                font-weight: 900;
                transition:ease-in-out 0.3s;
            }
            
            .event-calendar:hover {
                cursor:pointer;
                color: #000000;
                transition:ease-in-out 0.3s;
                

            }
            .today:hover {
                border: 2px solid #000;
                cursor: pointer;
                transition:ease-in-out 0.3s;
              
              
            }

            }
       
            .today-container {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
            }
            .today h3 {
           
                font-family: 'Staatliches', cursive;
                font-size: 1.5vw;
                font-weight: 700 !important;
                margin-bottom: -15%;
                color: #ffffff;
                margin-left: 4%;
             

            }
            .today h2 {

             
                font-weight: 300 !important;
                margin-bottom: 0%;
                margin-right: 0.5%;
                font-size: 1.5vw;
                color: #ffffff;
             
            }

           
            .main-menu {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .nav-logo a {
                text-decoration: none;
            }
        
@media (max-width: 768px) {
.nav a {
    font-size: 3vw;
    
}

.nav {
    height: 9vh;
}
}


@media (max-width: 769px) {
    .nav-container {
        display: none !important;
    }
    .nav-container-scrolled {
        display: none !important;
    }
}
