 .mobile-nav-bar {
	display: flex;
	position: fixed;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	top: 0px;
	z-index: 10;
	width: 100%;
	transition: 0.4s;
	background: #ffffffee;
	height: 4.5rem;
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.222);
	
}
.light-box {
	position: fixed;
	width: 45%;
	height: 100%;
	background-color: #308f59;
	z-index: 5;
	top: 0px;
	left: 0px;
	z-index: 999999;
	overflow-y: scroll;

}

.menu-header {
	display: flex;
}
.menu-header > span {
	position: absolute;
	right: 0;
	background-color: white;
	color: #308f59;
	font-weight: 700;
	font-size: 3.8vw;
	padding-top: 1.5vh;
	padding-bottom: 1.5vh;
	padding-left: 3vw;
	padding-right: 3vw;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 0px;
	letter-spacing: 2px;
}

.light-box-menu {
	display: table-caption;
	text-decoration: none;
	justify-content: left;
	color: white;
	display: flex;
	align-items: center;
	
	

}

.donate-button {
	background-color: white;
	color: #308f59 !important;
	font-weight: 700 !important;
	padding-left: 5vw;
	padding-right: 5vw;
	padding-top: 1vh;
	padding-bottom: 1.4vh;
	font-size: 5vw !important;
	border-radius: 50px;
}





.light-box-menu li {
	display: flex;
	align-items: center;
	justify-content: center;
	
	padding-top: 6vh;
	list-style: none;
}
.light-box-menu a {
	color: white;
	text-decoration: none;
	font-size: 5vw;
	font-weight: 500;
	white-space: nowrap;

}

.light-box-menu ul {
	margin-right: 8%;
}
.light-box-hide ul {
	display: none;
}

.light-box-hide {
	display: none;
}

.hamburgerMenuStyled {
	color: rgb(0, 0, 0);
	width: 8vw;
	height: auto;
	padding-right: 3vw;

}
.mobile-nav-logo {
	color: rgb(0, 0, 0);
	padding-left: 4vw;
	
}

.mobile-nav-logo img{
	width:13vw;
	height: auto;
}





.mobile-nav-logo h3 {
	font-weight: 700 ;

}


.closeBtnStyled {
	color: white;
	width: 8vw;
	height: auto;
	margin-top: 5%;
	margin-left: 5%;

};
.closeBtnHide {
	display: 'none'
};

@media (min-width: 350px){

	.mobile-nav-logo img{
		width:12vw;
		height: auto;
	}
}

@media (min-width: 530px){

	.mobile-nav-logo img{
		width:10vw;
		height: auto;
	}
}

@media (min-width: 630px){

	.mobile-nav-logo img{
		width:8vw;
		height: auto;
	}
}



@media screen and (min-width: 769px){
	.light-box ul {
		display: none !important;
	}
	.mobile-nav-bar {
		display: none !important;
	}
	
}

