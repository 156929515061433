
.home-header-content {
	width: 100%;
}

.home-header h1 {

	font-family: 'Staatliches', cursive;
	padding-top: 30% ; 
	padding-bottom: 30%;
	font-size: 8vw;
	text-align: left;
	  text-shadow: -5px 6px 4px rgb(25, 24, 24);
}


.home h3 {
	text-align: center;
	margin-top: 10%;
	margin-left: 5%;
	margin-right: 5%;
	font-size: 7vw;
	font-weight: 700;
	color: #4e4e4b;
	font-family: 'Staatliches', cursive;
	

}
.home-header {
	display: flex;
 



	padding-top:30%;
	padding-bottom: 20%;
	color: rgb(255, 255, 255);
	width: 100%;
	height: auto;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	font-family: 'Staatliches', cursive;

}
.home-body h1 {

	text-align: center;
}

.space {
	width: 48%;
}
.home-header-overlay {
	width: 100%;
	display: flex;
	justify-content: end;


}

.who-we-are-container {
	background-image: url('../img/island.webp');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

}
.who-we-are-overlay {
	background-color: #a26addd2;
	color: white;
	text-align: left;
	padding-top: 5%;
	padding-bottom: 5%;
	

}
.who-we-are-content {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
}

.who-we-are-content h1 {
	font-size: 10vw;
	
	font-weight: 700;
	font-family: 'Staatliches', cursive;
}
.who-we-are-content button {
	background-color: #ffffff;
	color: #a26add;
	font-weight: 700;
	padding-left: 5vw;
	font-size: 3vw;
	padding-right: 5vw;
	padding-top: 2vh;
	padding-bottom: 2vh;
	border: 2px solid #ffffff;
	margin-bottom: 5%;
	border-radius: 40px;
	margin-top: 5%;
}






@media (min-width: 320px){
	
}

@media (min-width: 481px){
	.home-header button {
		background-color: #008000;
		color: white;
		padding-left: 3.5vw;
		padding-right: 3.5vw;
		padding-top: 1.5vh;
		padding-bottom: 1.5vh;
	}

	


}

@media (min-width: 769px){
	.who-we-are-overlay {
		text-align: center;
		
	}
	.home-header button:hover {
		background-color: #00800000;
		color: #008000;
		border: 2px solid #008000;
		cursor: pointer;
	
	}
	.who-we-are-content button {

		font-size: 1.2vw;
	
	}
	.home-header-content {
		width: 50%;
	}
	.home-header {
		display: flex;
		padding-top:5%;
		padding-bottom: 5%;
	}
	
	.home-header h1 {

	
		padding-top: 20%;
		padding-bottom: 20%;
		font-size: 6vw;
		text-align: left;
		text-shadow: -5px 6px 4px rgb(25, 24, 24);
	}
	.home h3 {
	
		font-size: 3vw;
		margin-top: 5%;

	
	}
	
	.who-we-are-content h1 {
		font-size: 6vw;
		font-weight: 700;
	}
	.who-we-are-content p {
		font-size: 1.1vw;
		
	}
}





@media only screen and (min-width: 1201px) {

	

}
