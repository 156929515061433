.contact-header {
    display: flex;
    align-items: center;
  
    color: white;
    width: 100%;
    height: 85vh;
    background-size:cover;
    background-repeat: no-repeat;
    background-position:0% 100%;
    
}


.contact-header h1 {
    font-size: 13vw !important;
    padding-left: 15%;
    font-weight: 900;
    text-align: left;
    white-space: nowrap;
    font-family: 'Staatliches', cursive;
    text-shadow: -5px 6px 4px rgb(25, 24, 24);
  }

  .our-information {
    color: #a26add !important;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 5%;
    padding-bottom: 5%;
   
  }

  .our-information-container {
    background-color: white;
    border-radius: 10px;
    filter: drop-shadow(0px 9px 5px rgba(6, 0, 0, 0.128));
  }

  .our-information h1 {
    font-size:8vw;
    font-weight: 700;
  }
  .our-information span {
    font-size:3vw;
    font-weight: 400;
  }




.contact-container {
  display: flex;
  padding-top: 5%;
  padding-bottom: 5%;
  justify-content: center;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
  align-items: center;
  text-align:center;
  background-color: #a26add21;

}

.contact-container label {
  text-align:left;
}

.contact-form-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}



.contact-form-inputs div {
  display: flex;
  flex-direction: column;
}


.contact-form-input-group {

  gap: 0.5rem;
}



.contact-form-input-group input {
  border: none;
  width: 70vw;
  height: 4vh;
  margin-bottom: 5vh;
  margin-top: 2vh;
  outline: none;
  border-radius: 5px;

}
.message {

  width: 70vw !important;
  border: 2px solid rgba(119, 116, 116, 0.599);
  resize: none;
  border-radius: 10px;
  border: none;
  outline: none;
 

}


.contact-submit {
  background-color: #a26add;
  color: white;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top:15%;
  border-radius: 10px;
  font-size: 5vw;
  font-weight: 700;
  border: none;
  margin-bottom: 15%;
}

.contact-disabled {
  background-color: rgba(128, 128, 128, 0.544);
  color: white;
  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-top:15%;
  border-radius: 10px;
  font-size: 5vw;
  font-weight: 700;
  border: none;
  margin-bottom: 15%;
}

.contact-form-section {
  text-align: center;

}


.hide-contact-form {
  display: none !important;
}

.hide-our-information {
  display: none !important;
}


.contact-buttons {
  position: sticky;
  display: flex;
  gap: 0.7rem;
  align-items: center;
  margin-bottom: 10%;
  margin-top: 3%;
}


.contact-buttons > button {
  outline: none;
  background: none;
  border: 3px solid #a26add;
  border-radius: 10px;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  font-size: 4vw;
  color: #a26add;
  font-weight: 700;
  cursor: pointer;
}


.recaptcha {
  margin-bottom: 10%;
}
.contact-buttons > button {
  outline: none;
  background: none;
  border: 3px solid #a26add;
  border-radius: 10px;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  font-size: 4vw;
  color: #a26add;
  font-weight: 700;
  cursor: pointer;
}

.subject-section {
  margin-top: 10%;
}

.subject-buttons {
  display: flex;
  flex-direction: row !important;
  gap: 0.5rem;
}

.subject-buttons button {
  background-color: rgba(255, 255, 255, 0);
  font-weight: 700;
  padding-left: 3vw;
  padding-right: 3vw;
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  border-radius: 10px;

}


.contact-button-active {
  background-color: #a26add !important;
  border: 3px solid #ffffff !important;
  color: #ffffff !important;
  transition: 0.5s;
}

.contact-button-inactive {
  background-color: #00000000 !important;
  border: 3px solid #a26add !important;
  color: #a26add !important;
  transition: 0.5s;
}

.hide-subject2 {
  display: none !important;
}

.subject2 {
  margin-top: 5%;
}
.subject2 input {
  border:none;



}

@media (min-width: 1025px){
  .contact-header h1 {
    font-size: 6vw !important;
    text-align: left; 
    text-shadow: -5px 6px 4px rgb(25, 24, 24); 
  }
}

  @media (min-width: 769px){
    .contact-header h1 {
      font-size: 6vw !important;
      text-align: left;
      text-shadow: -5px 6px 4px rgb(25, 24, 24);
  }
  
  .our-information span {
    color: #a26add !important;
    font-size: 1vw !important;
  }
    .contact-buttons > button {
      font-size: 1.5vw;

    }
    .our-information {
      display:block;
      background-color:white;
      justify-content: center;
      align-items: center;
      color:#a26add;
      padding-top: 5%;
      padding-bottom: 5%;
      padding-left: 5%;
      padding-right: 5%;
      width:35vw;
      margin-top: 0%;
      border-radius: 15px;

    }
    .contact-button-active {
      background-color: #a26add !important;
      border: 3px solid #a26add !important;
      color: white !important;
      transition: 0.5s;
    }

    .contact-button-inactive {
      background-color: #ffffff00 !important;
      border: 3px solid #a26add!important;
      color: #a26add !important;
      transition: 0.5s;
    }

    .subject-button-inactive {
      background-color: #ffffff00 !important;
      border: 3px solid #a26add !important;
      color: #a26add !important;
      transition: 0.5s;
    }

    .our-information h1 {
      font-size: 2.8vw;
      white-space: nowrap;
    }
    .our-information span {
      font-size: 0.7vw;
      font-weight: 500;
      color: rgb(255, 255, 255);
    }

    .contact-buttons {
      margin-top: -2%;
      margin-bottom: 3%;
    }
    

    .our-information-content {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      justify-content: center;
      margin-top: 10%;
    }
   
    

    .our-information-content h4 {
      font-size: 1.5vw;
    }

    .information-icon {
      margin-bottom:0.1rem;
      margin-right: 0rem;
    }

    .contact-container form {
      background-color: white;
      height: auto;
      width: 42vw;
      display: flex;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      
    }

    .subject-buttons button {
      font-size: 1.3vw;
    
    }

    .contact-container {
      padding-left: 0% !important;
      padding-right: 0% !important;
      background-color: #a26add21;
      display: flex;
      align-items: center;
    }

    .contact-form-input-group {
      margin-top: 0%;
      gap: 3rem !important;
      padding-top: 0%;
    }
   

    .contact-form-inputs {
      flex-direction: column;
      padding-top: 6%;
    }
    .contact-form-input-group input {
      width: 15vw;
      height: 5vh;
      max-height: 2.5rem;
      resize: none;
      border: 2px solid rgba(119, 116, 116, 0.599);
      border-radius: 10px;
      margin-bottom: 0vh;
      margin-top: 0vh;
    }

    .message {
      width: 15vw !important;
      box-sizing: border-box; /* fit parent width */
      height: 100%;
      margin-bottom:0%;
      margin-top:0%;
      border: 2px solid rgba(119, 116, 116, 0.599);

    }

    
    .contact-submit {
      background-color: #a26add;
      color: rgb(255, 255, 255);
      padding-top: 1.5vh;
      padding-bottom: 1.5vh;
      padding-left: 2.5vw;
      padding-right: 2.5vw;
      font-size: 1.3vw;
      margin-bottom: 20% !important;
      margin-top: 8%;
      font-weight: 700;
      border: 4px solid #a26add;
      transition: 0.3s;
      
    }

    .contact-submit:hover {
      background-color: #ffffff;
      border: 4px solid #a26add;
      color: #a26add;
      transition: 0.3s;
    }

    .contact-disabled {
      background-color: #8f8f8f;
      color: rgb(255, 255, 255);
      padding-top: 1.5vh;
      padding-bottom: 1.5vh;
      padding-left: 2.5vw;
      padding-right: 2.5vw;
      font-size: 1.3vw;
      margin-bottom: 20% !important;
      margin-top: 8%;
      font-weight: 700;
    }


    .test {
      padding-right: 4vw;
    }
    .contact-form-input-group label {
      text-align: left;
      font-size: 1vw;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.882);
    }
    .subject2 label {
      text-align: left;
      font-size: 1vw;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.882);
    }
    .contact-form-input-group {
      gap: 4rem;
      flex-direction: row !important;

    }

    .group-spacing {
      display: flex;
      gap: 2.5rem;
    }
}

@media (min-width: 1025px){

  }